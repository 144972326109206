<template>
  <v-card elevation="4">
    <div style="display: flex; justify-content: space-between; align-items: center;">
      <v-card-title>Sales</v-card-title>
      <date-range-picker
        style="float: right; margin-right: 10px"
        ref="picker"
        opens="left"
        :max-date="max_date"
        :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
        :showWeekNumbers="true"
        :showDropdowns="true"
        :autoApply="false"
        v-model="dateRange"
        @update="updateDateRange"
        :linkedCalendars="false"
      >
      </date-range-picker>
       <v-select
          style="margin-right: 10px"
          v-model="selectedTrialOption"
          dense
          :items="['Excluding Trial','Including Trial']"
          label="Trial"
          @change="updateIncludeTrialValue"
          outlined
        >
        </v-select>
    </div>

    <donut-chart
      style="padding: 10px"
      v-if="Object.keys(packageWiseShop).length && !loading"
      :datachart="packageWiseShop"
      :chartheight="500"
      chartid="package-wise-shop"
    ></donut-chart>
    <div v-else class="text-center" style="padding: 150px 0px">
      <img :src="require('@/assets/images/misc/loading.gif')" alt="" width="100px" />
    </div>
  </v-card>
</template>

<script>
import moment from 'moment'
import DonutChart from '../../Charts/DonutChart.vue'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  data() {
    return {
      selectedTrialOption: 'Excluding Trial', 
      loading: false,
      packageWiseShop: {},
      filters: {
        include_trial: 0
      },
      dateRange: {
        startDate: null,
        endDate: null,
      },
      max_date: null
    };
  },
  components: {
    DonutChart,
    DateRangePicker:  DateRangePicker ,
  },

  mounted() {
    this.dateRange.startDate = moment().subtract(30, 'days').format('YYYY-MM-DD')
    this.dateRange.endDate = moment().format('YYYY-MM-DD')
    this.fetchPackageWiseShop();
    this.max_date = moment().add(1, 'day').toDate(); // Set max_date to tomorrow's date
  },


  methods: {
       async fetchPackageWiseShop() {
      this.loading = true;
      try {
        const startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD');
        const endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD');
        const params = {
          startDate,
          endDate,
          include_trial: this.filters.include_trial
        };
        let response = await axios.get("admin/dashboard-data/package-wise-shops", { params });
        if (response.data.success) {
          this.packageWiseShop = response.data.data;
        }
      } catch (e) {
      } finally {
        this.loading = false;
      }
    },
    updateDateRange() {
      this.fetchPackageWiseShop();
    },
    updateIncludeTrialValue() {
        if (this.selectedTrialOption === 'Excluding Trial') {
          this.filters.include_trial = 0;
        } else {
          this.filters.include_trial = 1;
        }
        this.fetchPackageWiseShop();
      },
  },
}
</script>
