<template>
  <apexchart type="line" :options="options" :series="series"></apexchart>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
  props: {
    datachart: {
      type: Object,
      requred: true,
    },
    chartid: {
      type: String,
    },
    chartheight: {
      type: Number,
      default: 300,
    },
  },
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      options: {},
      series: [],
    }
  },

  mounted() {
    // this.options.chart.id = this.chartid
    // this.options.xaxis.categories = this.datachart.xaxis
    this.options = {
    
      xaxis: {
        categories: this.datachart.xaxis,
      },
      //   stroke: {
      //     curve: 'straight',
      //   },
      //   grid: {
      //     row: {
      //       colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
      //       opacity: 0.5,
      //     },
      //   },
      chart: {
        toolbar: {
          show: false,
          autoSelected: 'pan',
        },
        height: this.chartheight,
        type: 'line',
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
    }

    this.series = [
      {
        name: this.datachart.label,
        data: this.datachart.yaxis,
      },
    ]

    if (this.datachart.yaxis1) {
      this.series.push({
        name: this.datachart.label,
        data: this.datachart.yaxis1
      });
    }
    console.log(this.datachart)
  },
}
</script>