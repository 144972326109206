<template>
  <div>
    <v-row class="match-height">
      <v-col cols="12" sm="3">
        <statistics-card-vertical
          color="green"
          :statistics="`${legacy_queues.product_upload_queue}`"
          stat-title="Product Uploader Queue"
          subtitle="Products updating form Shopify to Etsy"
          :icon="icons.mdiUpload"
        ></statistics-card-vertical>
      </v-col>

      <v-col cols="12" sm="3">
        <statistics-card-vertical
          color="primary"
          :statistics="`${legacy_queues.order_queue}`"
          stat-title="Etsy Orders Queue"
          subtitle="Orders synced from Etsy & uploading to Shopify"
          :icon="icons.mdiCart"
        ></statistics-card-vertical>
      </v-col>

      <v-col cols="12" sm="3">
        <statistics-card-vertical
          color="accent"
          :statistics="`${legacy_queues.shopify_upload_queue}`"
          stat-title="Shopify Upload Queue"
          subtitle="Products uploading from Etsy to Shopify"
          :icon="icons.mdiUpload"
        ></statistics-card-vertical>
      </v-col>

      <v-col cols="12" sm="3">
        <statistics-card-vertical
          color="warning"
          :statistics="`${legacy_queues.metafields_queue}`"
          stat-title="Metafields Queue"
          subtitle="Metafields from Shopify"
          :icon="icons.mdiSegment"
        ></statistics-card-vertical>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <merchant-growth></merchant-growth>
      </v-col>
      <v-col cols="6">
        <package-wise-shop></package-wise-shop>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiUpload, mdiCart, mdiSegment, mdiDotsVertical } from '@mdi/js'
import StatisticsCardVertical from '@/components/statistics-card/StatisticsCardVertical.vue'
import DonutChart from '../Charts/DonutChart.vue'
import MerchantGrowth from './Charts/MerchantGrowth.vue'
import PackageWiseShop from './Charts/PackageWiseShop.vue'
export default {
  data() {
    return {
      legacy_queues: {
        metafields_queue: 0,
        order_queue: 0,
        product_upload_queue: 0,
        shopify_upload_queue: 0,
      },

      icons: {
        mdiUpload: mdiUpload,
        mdiCart: mdiCart,
        mdiSegment: mdiSegment,
        mdiDotsVertical: mdiDotsVertical,
      },

      packageWiseShop: {},
      merchantGrowth: {},
    }
  },
  components: {
    StatisticsCardVertical,
    DonutChart,
    MerchantGrowth,
    PackageWiseShop
  },

  mounted() {
    this.fetchDashboardData()
    this.fetchPackageWiseShop()
  },

  methods: {
    async fetchDashboardData() {
      try {
        let response = await axios.get('admin/dashboard-data')
        if (response.data.success) {
          this.legacy_queues = response.data.data.legacy_queues
        }
      } catch (e) {}
    },

    async fetchPackageWiseShop() {
      try {
        let response = await axios.get('admin/dashboard-data/package-wise-shops')
        if (response.data.success) {
          this.packageWiseShop = response.data.data
        }
      } catch (e) {}
    },
  },
}
</script>
