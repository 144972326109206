<template>
  <apexchart type="donut" :options="options" :series="series"></apexchart>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
  props: {
    datachart: {
      type: Object,
      requred: true,
    },
    chartid: {
      type: String,
    },
    chartheight: {
      type: Number,
      default: null,
    },
  },
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      options: {},
      series: [],
    }
  },

  mounted() {
    // this.options.chart.id = this.chartid
    // this.options.xaxis.categories = this.datachart.xaxis
    this.options = {
      chart: {
        height: this.chartheight,
        id: this.chartid,
      },
      labels: this.datachart.xaxis,
     
    }

    this.series = this.datachart.yaxis
  },
}
</script>