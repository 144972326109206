<template>
  <v-card elevation="4">
    <v-card-title>
      Merchant Growth ({{ merchantGrowth.merchant_growth }})
      <v-spacer></v-spacer>
      <span style="font-weight: 100; font-size: 15px">{{ selectables[filters.range] }}</span>
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon light>{{ icons.mdiDotsVertical }}</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-for="(item, i) in selectables" :key="i" @click="changeRangeFilter(i)">
            <v-list-item-title class="cursor-pointer">{{ item }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-title>
    <line-chart
      style="padding: 10px"
      v-if="(Object.keys(merchantGrowth).length && !loading)"
      :datachart="merchantGrowth"
      chartid="package-wise-shosdfp"
      :chartheight="400"
    ></line-chart>
    <div v-else class="text-center" style="padding: 150px 0px">
        <img :src="require('@/assets/images/misc/loading.gif')" alt="" width="100px" />
    </div>
  </v-card>
</template>

<script>
import { mdiDotsVertical } from '@mdi/js'
import LineChart from '../../Charts/LineChart.vue'
export default {
  data() {
    return {
      loading: false,
      merchantGrowth: {},
      icons: {
        mdiDotsVertical: mdiDotsVertical,
      },
      selectables: {
        last30days: 'Last 30 Days',
        last7days: 'Last 7 Days',
        last90days: 'Last 90 Days',
        lastmonth: 'Last Month',
        lastyear: 'Last Year',
        thisvslastmonth: 'This vs Last Month',
        custom: 'Custom',
      },
      filters: {
        range: 'last30days',
      },
    }
  },
  components: {
    LineChart,
  },

  mounted() {
    this.fetchMerchantGrowth()
  },

  methods: {
    async fetchMerchantGrowth() {
      this.loading = true
      try {
        let response = await axios.get('admin/dashboard-data/merchant-growth', { params: this.filters })
        if (response.data.success) {
          this.merchantGrowth = response.data.data
        }
      } catch (e) {
      } finally {
        this.loading = false
      }
    },

    changeRangeFilter(range) {
      if (range == 'custom') {
        this.$swal.fire({
          iconHtml:
            '<img src="https://i.ibb.co/Ks344X0/vector-yellow-crying-emoticon-with-opened-eyes.png" width="140px">',
          title: 'गाह्रो छ',
          text: 'Custom Date Selection बनाउन लस्टै टाईम लग्छ :(',
          confirmButtonText: 'हस',
          customClass: {
            icon: 'no-border',
          },
        })

        return
      }
      this.filters.range = range
      this.fetchMerchantGrowth()
    },
  },
}
</script>
